/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800;900&display=swap');

@import '~quill/dist/quill.snow.css';

body {
  font-family: 'Open Sans', sans-serif;
}

:root {
  --background-color: #01002a;
  --primary-color: #CECECE;
  --hr-color: #c4c4c4;
  --font-family: "Open Sans", sans-serif;
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 0.4rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 1rem;

  background-color: var(--primary-color);
}


a:-webkit-any-link {
  text-decoration: none;
  color: inherit;
}


input[type=file] {
  width: 350px;
  max-width: 100%;
  color: #444;
  padding: 5px;
  background: #fff;
  border-radius: 10px;
  border: 2px solid #1475cf;
  cursor: pointer;
}

input[type=file]::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #084cdf;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: .2s ease-in-out;
}

input[type=file]::file-selector-button:hover {
  background: #0d45a5;
}

.ck-editor__editable {
  min-height: 140px;
}

.stacky {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

@media screen and (max-width: 725px) {
  .stacky {
    flex-direction: column;
  }

}

.subsriber-list span {
  font-weight: 300;
}